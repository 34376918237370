import React, { Suspense, useEffect } from "react";
import { createBrowserRouter } from "react-router-dom";
import WithScript from "../pages/WithScript";
import { getDataByCountry, states } from "../utils";

const { country, mainSite, state } = getDataByCountry();

const Promo = React.lazy(() => import("../pages/Promo"));
const Promo20Upto100 = React.lazy(() =>
  import("../pages/Promo/Promo20Upto100")
);
const PromoUpto1500 = React.lazy(() => import("../pages/Promo/PromoUpto1500"));
const Promo100UpTo1650 = React.lazy(() =>
  import("../pages/Promo/Promo100UpTo1650")
);
const Promo100UpTo150Plus15 = React.lazy(() =>
  import("../pages/Promo/Promo100UpTo150Plus15/Promo100UpTo150Plus15")
);
const Promo200spins = React.lazy(() => import("../pages/Promo/Promo200spins"));
const UK50Promo = React.lazy(() => import("../pages/UK50Promo"));
const UK100Promo = React.lazy(() => import("../pages/UK100Promo"));
const UKMatchUp = React.lazy(() => import("../pages/UKMatchUp"));
const Sport = React.lazy(() => import("../pages/Sport"));
const B10G30S15 = React.lazy(() => import("../pages/Sport/B10G30S15"));
const B20G20S20 = React.lazy(() => import("../pages/Sport/B20G20S20"));
const WelcomeLP = React.lazy(() => import("../pages/WelcomeLP"));
const Matchup200 = React.lazy(() => import("../pages/Matchup200"));
const Promo100UpTo100 = React.lazy(() =>
  import("../pages/Promo/Promo100UpTo100")
);

const LazyLoadWrapper = ({
  component: Component,
  fallback = null,
  extra = null,
}) => {
  return (
    <Suspense fallback={fallback}>
      <Component extra={extra} />
    </Suspense>
  );
};

const shouldRedirect = (isRestricted, specialCountries) => {
  const isOntario = state === states.ONTARIO;

  if (isOntario) {
    if (!isRestricted && specialCountries.includes(states.ONTARIO)) {
      return true;
    }

    if (isRestricted && !specialCountries.includes(states.ONTARIO)) {
      return true;
    }
  }

  if (!isRestricted && specialCountries.includes(country)) {
    return true;
  }

  if (isRestricted && !specialCountries.includes(country)) {
    return true;
  }

  return false;
};

const routeWithSuspense = ({
  path,
  component,
  extra,
  isRestricted,
  specialCountries,
  fallback = <div>Loading...</div>,
}) => {
  const isRedirected = shouldRedirect(isRestricted, specialCountries);

  return {
    path,
    element: (
      <LazyLoadWrapper
        component={isRedirected ? CatchAllRedirect : component}
        extra={extra}
        fallback={fallback}
      />
    ),
  };
};

const CatchAllRedirect = () => {
  useEffect(() => {
    window.location.href = `${mainSite}/promotions`;
  }, []);
  return null;
};

const routesByCountry = [
  routeWithSuspense({
    path: "/",
    component: Promo,
    isRestricted: false,
    specialCountries: ["DK"],
  }),

  routeWithSuspense({
    path: "/t",
    component: WithScript,
    isRestricted: false,
    specialCountries: [],
  }),

  routeWithSuspense({
    path: "/100for100",
    component: Promo100UpTo100,
    isRestricted: true,
    specialCountries: ["DK"],
  }),

  routeWithSuspense({
    path: "/promo",
    component: Promo,
    isRestricted: false,
    specialCountries: ["DK"],
  }),

  routeWithSuspense({
    path: "/promo20",
    component: Promo20Upto100,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/promoUpTo100",
    component: Promo20Upto100,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/promo100UpTo1650",
    component: Promo100UpTo1650,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/promofruityslots",
    extra: { logoName: "fruity" },
    component: Promo100UpTo150Plus15,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/promohideousslots",
    extra: { logoName: "hideous" },
    component: Promo100UpTo150Plus15,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/promoUpTo1500",
    component: PromoUpto1500,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/promo200",
    component: Promo200spins,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/uk50promo",
    component: UK50Promo,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/uk100promo",
    component: UK100Promo,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/uk50matchup",
    component: UKMatchUp,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/uk35matchup",
    component: UKMatchUp,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/uk25matchup",
    component: UKMatchUp,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/sport",
    component: Sport,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/B10G30S15",
    component: B10G30S15,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "/B20G20S20",
    component: B20G20S20,
    isRestricted: true,
    specialCountries: ["UK", "GB"],
  }),
  routeWithSuspense({
    path: "/welcome_lp",
    extra: {
      graphic: "welcome_lp",
      offer: "35_50",
    },
    isRestricted: false,
    specialCountries: ["DK"],
    component: WelcomeLP,
  }),
  routeWithSuspense({
    path: "/up_to_300_15_spins",
    extra: {
      graphic: "welcome_lp",
      offer: "300_15",
    },
    isRestricted: false,
    specialCountries: ["DK"],
    component: WelcomeLP,
  }),
  routeWithSuspense({
    path: "/up_to_1650_300_spins",
    extra: {
      graphic: "welcome_lp",
      offer: "1650_300",
    },
    isRestricted: false,
    specialCountries: ["DK"],
    component: WelcomeLP,
  }),
  routeWithSuspense({
    path: "/up_to_1500_300_spins",
    extra: {
      graphic: "welcome_lp",
      offer: "1500_300",
    },
    isRestricted: true,
    specialCountries: ["CA", "IE", states.ONTARIO],
    component: WelcomeLP,
  }),
  routeWithSuspense({
    path: "/up_to_1500_300_spins_ca",
    extra: {
      graphic: "welcome_lp",
      offer: "1500_300",
    },
    isRestricted: true,
    specialCountries: ["CA", "IE", states.ONTARIO],
    component: WelcomeLP,
  }),
  routeWithSuspense({
    path: "/P100M100S0",
    extra: {
      graphic: "welcome_lp",
      offer: "P100M100S0",
    },
    isRestricted: false,
    specialCountries: ["DK", states.ONTARIO],
    component: WelcomeLP,
  }),
  routeWithSuspense({
    path: "/P100M100S20",
    extra: {
      graphic: "welcome_lp",
      offer: "P100M100S20",
    },
    isRestricted: false,
    specialCountries: ["DK", states.ONTARIO],
    component: WelcomeLP,
  }),
  routeWithSuspense({
    path: "/P100M200S25",
    extra: {
      graphic: "welcome_lp",
      offer: "P100M200S25",
    },
    isRestricted: false,
    specialCountries: ["DK", states.ONTARIO],
    component: WelcomeLP,
  }),
  routeWithSuspense({
    path: "/P100M25S25",
    extra: {
      graphic: "welcome_lp",
      offer: "P100M25S25",
    },
    isRestricted: false,
    specialCountries: ["DK", states.ONTARIO],
    component: WelcomeLP,
  }),
  routeWithSuspense({
    path: "/P100M50S110",
    extra: {
      graphic: "welcome_lp",
      offer: "P100M50S110",
    },
    isRestricted: false,
    specialCountries: ["DK", states.ONTARIO],
    component: WelcomeLP,
  }),
  routeWithSuspense({
    path: "/fishin_frenzy",
    extra: {
      graphic: "fishin_frenzy",
      offer: "35_50",
    },
    isRestricted: false,
    specialCountries: ["DK"],
    component: WelcomeLP,
  }),

  routeWithSuspense({
    path: "/matchup200",
    component: Matchup200,
    isRestricted: false,
    specialCountries: ["DK"],
  }),
  routeWithSuspense({
    path: "*",
    isRestricted: false,
    specialCountries: [],
    component: CatchAllRedirect,
  }),
];

const router = createBrowserRouter(routesByCountry);

export default router;
